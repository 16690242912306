import { Container, Stack, Typography } from '@mui/material'
import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import React from 'react'
import TimelapseIcon from '@mui/icons-material/Timelapse'
import CalendarTodayIcon from '@mui/icons-material/CalendarToday'
import Grid from '@mui/material/Grid'

const Program: React.FC<Props<Programme>> = ({ data }) => {
  const [expanded, setExpanded] = React.useState<string | false>('panel4')

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false)
    }

  return (
    <Container sx={{ py: 8 }}>
      <Stack alignItems="center">
        <Typography
          textAlign="center"
          variant="h1"
          sx={{ width: 1 }}
          noWrap
          fontWeight={800}
          color="accent.main"
        >
          {data?.sub}
        </Typography>
        <Typography
          variant="h4"
          textAlign="center"
          fontWeight={700}
          sx={{ mt: '-70px' }}
        >
          {data?.titre}
        </Typography>
      </Stack>
      <Typography
        color="text.secondary"
        textAlign="center"
        sx={{ width: { xs: 1, md: 0.7 }, mx: 'auto', mt: 4 }}
      >
        {data?.description}
      </Typography>
      <Stack sx={{ pt: 8 }}>
        {data?.etapes?.map((el, i) => (
          <Accordion
            expanded={expanded === `panel${i}`}
            onChange={handleChange(`panel${i}`)}
            disableGutters
            TransitionProps={{ unmountOnExit: true }}
            elevation={0}
            sx={{
              '&.MuiAccordion-root:before': {
                display: 'none',
              },
            }}
            key={i}
          >
            <AccordionSummary
              sx={{
                borderBottom: 0,
                display: 'flex',
                alignItems: 'stretch',
                m: 0,
              }}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Stack
                sx={{
                  width: 6,
                  height: 1,
                  bgcolor: 'primary.main',
                  mr: 2,
                  borderRadius: 6,
                }}
              ></Stack>
              <Stack
                direction="row"
                sx={{
                  width: 1,
                  px: 2,
                  py: 1,
                  bgcolor: expanded === `panel${i}` ? 'secondary.main' : 'none',
                  borderRadius: 2,
                }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12} md={4}>
                    <Typography sx={{ width: 300 }} fontWeight={700}>
                      {el.titre}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <Stack
                      direction={{ xs: 'column', md: 'row' }}
                      spacing={{ xs: 2, md: 8 }}
                    >
                      <Stack direction="row" spacing={1}>
                        <TimelapseIcon />
                        <Typography>{el.duree}</Typography>
                      </Stack>
                      <Stack direction="row" spacing={1}>
                        <CalendarTodayIcon />
                        <Typography>{el.date}</Typography>
                      </Stack>
                    </Stack>
                  </Grid>
                </Grid>
              </Stack>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container>
                <Grid item xs={12} md={4}></Grid>
                <Grid item xs={12} md={8}>
                  {data?.etapes.length - 1 === i &&
                    data?.details?.map((step) => (
                      <Stack
                        direction="row"
                        spacing={1}
                        key={step?.id}
                        sx={{ my: 2 }}
                      >
                        <Stack
                          sx={{
                            width: 6,
                            bgcolor: 'secondary.main',
                            ml: '-10px',
                            borderRadius: 6,
                          }}
                        ></Stack>
                        <Stack
                          direction={{ xs: 'column', md: 'row' }}
                          spacing={{ xs: 2, md: 8 }}
                          sx={{
                            width: 1,
                            px: 2,
                            py: 1,
                            bgcolor:
                              expanded === `panel${i}`
                                ? 'secondary.light'
                                : 'none',
                            borderRadius: 2,
                          }}
                        >
                          <Stack direction="row" spacing={1}>
                            <TimelapseIcon />
                            <Typography>{step?.duree} </Typography>
                          </Stack>
                          <Stack spacing={2}>
                            <Typography>{step?.titre}</Typography>
                            <Typography color="text.secondary">
                              {step?.description}
                            </Typography>
                          </Stack>
                        </Stack>
                      </Stack>
                    ))}
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        ))}
      </Stack>
    </Container>
  )
}

export default Program
